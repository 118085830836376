import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

import { objectToQueryString } from '../../../../Utils/Common/utils';
const getInterviewCreditsURL = '/host-company/admin-setting/credits';
export const CreditsApi = createApi({
  reducerPath: 'creditsAPi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getCreditsTransactions: builder.query({
      query: (request) => {
        const queryString = request ? objectToQueryString(request) : '';
        const updatedUrl = queryString
          ? `${getInterviewCreditsURL}?${queryString}`
          : getInterviewCreditsURL;
        return {
          url: updatedUrl,
          method: 'GET',
          body: null,
        };
      },
    }),
  }),
});

export const { useGetCreditsTransactionsQuery } = CreditsApi;

export default CreditsApi.reducer;
