import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const getLoggedInUserDetailsURL = '/host-company/user';
export const GetProfileApi = createApi({
  reducerPath: 'loggedInHCUser',
  baseQuery: customBaseQuery,
  tagTypes: ['loggedInHCUserApi'],
  endpoints: (builder) => ({
    getLoggedInUser: builder.query({
      query: () => ({
        url: getLoggedInUserDetailsURL,
        method: 'GET',
        body: null,
      }),
      providesTags: ['loggedInHCUserApi'],
    }),
  }),
});

export const { useGetLoggedInUserQuery } = GetProfileApi;

export default GetProfileApi.reducer;
