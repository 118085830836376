import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const getUpcomingInterviewsUrl = '/host-company/dashboard/interviews';
const getChecklistUrl = '/host-company/pocs/checklist-items';
const updateChecklistStatusUrl = '/host-company/pocs';

export const DashboardApi = createApi({
  reducerPath: 'DashboardApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getUpcomingInterviews: builder.query({
      query: () => {
        return { url: getUpcomingInterviewsUrl, method: 'GET', body: null };
      },
    }),
    getChecklist: builder.query({
      query: () => {
        return { url: getChecklistUrl, method: 'GET', body: null };
      },
    }),
    updateChecklistStatus: builder.mutation({
      query: (body) => {
        return { url: updateChecklistStatusUrl, method: 'PATCH', body };
      },
    }),
  }),
});

export const {
  useGetUpcomingInterviewsQuery,
  useGetChecklistQuery,
  useUpdateChecklistStatusMutation,
} = DashboardApi;
export default DashboardApi.reducer;
